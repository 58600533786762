@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  position: relative;
}

@font-face {
  font-family: "Gulfs";
  src: url(../fonts/GulfsDisplay-Normal.woff) format("woff"),
    url(../fonts/GulfsDisplay-Normal.woff2) format("woff2");
}
